import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: fixed;
  width: 100%;
  padding: 16px 16px 6px 16px;
`;

export const StyledBackButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background-color: ${({ theme }) => theme.colors.$white};
  opacity: 90%;
  border-radius: 50%;
  border: none;
`;
